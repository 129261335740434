.popup{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 2em;
	margin: 2em;
	width: 500px;
	max-height: 600px;
	min-width: 250px;
	min-height: 250px;
	background:var(--purple-color);
	border-radius: 30px;
	position: relative;
	color: white;
}

.popup > .body{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	font-size: 1.5em;
	margin: 30px 0px;
	cursor: default;
}
.popup > .body > span {
	text-align: center;
}

.popup>.close{
	display: flex;
	justify-content: center;
	align-items: center;
	padding:5px;
	position: absolute;
	top:20px;
	right:20px;
	width:30px;
	height:30px;
	padding:5px;
	border-radius: 50%;
	cursor: pointer;
	background-color: transparent;
}


.popup > .ok{
		margin:auto;
		width: 200px;
		height: 50px;
		border-radius: var(--buttons-border-radius);
		background: #C20272;
		color: #fff;
		font-size: 1.5em;
		font-family: 'Space Grotesk', sans-serif;
		cursor: pointer;
		font-weight: 400;
}
