.root {
	height: 100%;
  }

.container {
  display: flex;
  width: 100%;
	padding-bottom: 35px;
}

.inner-containers {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.left-section {
	flex-direction: column;
	gap: 35px;
	color: #fff;
	text-align: left;
	letter-spacing: 0.5px;
	justify-content: start;
	align-items: flex-start;
	padding-left: 10vw;
}

.title-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title-section>h1{
	font-size: 40px;
}
.title-section>h2{
	font-size: 30px;
}

.description {
	padding-right: 20px;
	font-size: 18px;
}
.description a{
	text-decoration: underline;
	white-space: nowrap;
	color: #eb4ba8;
}
.carmela-maceo-image {
  width: 700px;
  height: 700px;
  border-radius: 10%;
}

.total-price {
	display: flex;
	flex-direction: column;
	gap: 3px;
	text-align: center;
	align-items: flex-start;
	justify-content: center;
}

.total-price p, .total-minted {
  font-size: 20px;
}
.total-price>p>strong, .total-minted{
	white-space: nowrap;
}

.mint-container{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.collection-button>a{
	color:white;
	display: flex;
	align-items: center;
	justify-content: center;
	width:180px;
	height:45px;
	margin-bottom: 10px;
	background-color: #6f60fc;
	border-radius: 10px;
	font-family: 'Space Grotesk';
	font-size: 14px;
}

@media screen and (max-width: 1400px) {
  .carmela-maceo-image {
    width: 600px;
    height: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .carmela-maceo-image {
    width: 500px;
    height: 500px;
  }
}

@media screen and (max-width: 992px) {
  .carmela-maceo-image {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
  }
  .inner-containers {
    width: 100%;
    justify-content: center;
    align-items: center;
		margin-bottom: 3em;
  }
	.inner-containers img{
		min-width: 300px;
		height: auto;
	}
  .left-section {
    flex-direction: column;
    color: #fff;
    justify-content: space-evenly;
    letter-spacing: 0.5px;
		padding-left: 0;
  }
	.description, .title-section, .total-price, .total-minted {
    text-align: center;
		padding: 0 20px;
  }
	.mint-container{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.total-price{
		align-items: center;
		padding: 0 20px;
	}
}
