* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

:root{
	--red-color: #ED1C24;
	--purple-color: #351c75;

	--buttons-border-radius: 10px;
}

html {
	scroll-behavior: unset !important;
	font-family: 'Space Grotesk', sans-serif;
	background-image: linear-gradient(to right, #173e6f , #3d2982);
	height:100vh
}
@media screen and (max-width:768px){
	html{
		height: 100%;
	}
}

  body {
	font-size: 14px;
	font-weight: 400;
	word-spacing: 0;
	/* background-color: #351c75; */
	font-family: #fff;
  }

	a{
		text-decoration: none;
		color: inherit;
	}

	img {
		width: 100%;
		height: auto;
	}

  .root {
	height: 100vh;
  }

button {
	border: unset;
	outline: unset;
	cursor: pointer;
}
