.homepage {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.minter{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap:20px;
	width: 350px;
	padding-bottom: 2em;
}
.minter>.minter-image>img{
		width:250px;
    height:250px;
    border-radius:50%;
}
.minter>.minter-title{
	color: #fff;
	text-align: center;
	font-size: 20px;
	margin: 20px 0px 0px 0px;
	line-height: 2em;
}
.minter>.minter-title>h2{
	font-weight: 400;
}
.minter>.minter-about{
	text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
	line-height: 1.5em;
	min-height: 120px;
}

.base-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 200px;
}

.link-button {
  background: #C20272;
	color: #fff;
  border-radius: 10px;
  font-size: 30px;
  width: 350px;
  text-align: center;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
	font-weight: 600;
}

.link-button:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

.link {
  text-decoration: none;
  color: white;
  padding: 30px 130px;
}

.ready-class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  color: white;
	margin-bottom:1em;
}

.ready-class h1 {
  font-size: 50px;
  text-align: center;
}

@media screen and (max-width: 992px) {
	.base-button {
		flex-direction: column;
		gap: 60px;
	}
	.minter>.minter-about{
		min-height: auto;
		padding: 0 10px;
	}
	.minter>.minter-title{
		text-align: center;
	}
	.ready-class{
		margin:1em 0;
	}
	.ready-class h1{
		font-size: 40px;
		padding: 0 10px;
	}
	.link-button{
		width:200px;
		margin:0 10px
	}
}

@media screen and (max-width: 374px) {
	.ready-class{
		font-size: 1em;
	}
	.minter{
		width:auto;
	}
}
