header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	height: 70px;
	margin-bottom: 9px;
}

.header-logo {
	display: flex;
	margin-top: 1em;
	justify-content: flex-end;
}
.header-logo a{
	margin-left: 20px;
}
.header-logo img{
	height: 40px;
  width: auto;
}

/* Wallet Adapter */
.wallet-adapter-button {
    border-radius: 0px;
}
.wallet-adapter-button-trigger {
		background-color: transparent;
		margin-right: 1em;
}
@media screen and (max-width:375px) {
	.wallet-adapter-button-trigger{
		margin-right: 0;
	}
}
