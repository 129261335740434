.mint-button {
  display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
  height: 85px;
  border-radius: var(--buttons-border-radius);
  background: #C20272;
  border: none;
  color: #fff;
  font-size: 35px;
	font-family: 'Space Grotesk', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
	margin-bottom: 20px;
	font-weight: 800;
}
.mint-button-disabled {
  background: #3f3f3f !important;
	pointer-events: none;
}

.mint-button:hover {
  transform: scale(1.03);
}
