.container-styles {
  position: relative;
	/* width: 100%; */
	width:400px;
	height: 25px;
  background-color: #e0e0de;
  border-radius: 20px;
	overflow: hidden;
}

.filler-styles {
  height: 100%;
  /* border-radius: inherit; */
  background-color: #C20272;
}

.label-wrapper{
	position: absolute;
	left: 0px;
	top: 0px;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.label-styles {
  color: black;
  font-weight: bold;
}

.progress-container {
  display: flex;
  flex-direction: column;
	gap: 20px;
  /* width: 350px; */
  gap: 30px;
}

@media screen and (max-width: 768px) {
	.container-styles{
		width:100%;
	}
}
